<template>
    <div id="app"
         :class="[
             { collapsed: isMenuCollapsed }, 
             {'isOnline': !user.isOffline},
             { onmobile: isOnMobile }
         ]">

        <!--Offline menu-->
        <b-navbar toggleable
                  type="dark"
                  variant="dark"
                  class="topMenu"
                  v-if="user.isOffline">
            <b-navbar-brand>
                <NavbarLogotype :user="user" />
            </b-navbar-brand>
            <b-navbar-nav class="ml-auto">
                <b-button variant="primary"
                          size="sm"
                          :to="{name:'startPage'}">Egenkontroller</b-button>
            </b-navbar-nav>
        </b-navbar>

        <!--Mobile menu-->
        <b-navbar toggleable
                  type="dark"
                  variant="dark"
                  class="topMenu"
                  v-if="isOnMobile && !user.isOffline">
            <b-navbar-brand>
                <NavbarLogotype :user="user" />
            </b-navbar-brand>
            <b-navbar-nav class="ml-auto">
                <b-nav-item @click="isMenuCollapsed = false">
                    <font-awesome-icon icon="bars" />
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>

        <div>
            <router-view class="container-fluid" />
            <!--Sidebar menu-->
            <div v-if="!user.isOffline">
                <sidebar-menu :menu="menu"
                              :collapsed="isMenuCollapsed"
                              width="280px"
                              :hide-toggle="false"
                              :show-child="false"
                              :show-one-child="true"
                              @toggle-collapse="onToggleCollapse"
                              @item-click="onItemClick">
                    <div slot="header" />
                    <div slot="footer">
                        <div v-if="user.user" class="text-white text-center">
                            <a @click="logout"
                               v-b-tooltip.hover
                               href="#"
                               title="Logga ut">
                                <font-awesome-icon icon="sign-out-alt" />
                                <span v-show="!isMenuCollapsed">{{ user.user.fullName }}</span>
                            </a>
                        </div>
                    </div>
                    <span slot="toggle-icon"><font-awesome-icon icon="arrows-alt-h" /></span>
                    <span slot="dropdown-icon"><font-awesome-icon icon="chevron-right" /></span>
                </sidebar-menu>
                <div v-if="isOnMobile && !isMenuCollapsed"
                     class="sidebar-overlay"
                     @click="collapseMenuOnOutsideClick()" />
                <div class="sidebar-menu-mobile--toggle-button-wrapper">
                    <b-button @click="isMenuCollapsed = false">
                        <font-awesome-icon icon="arrows-alt-h" />
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import MenuLogotype from '@/components/MenuLogotype';
    import NavbarLogotype from '@/components/NavbarLogotype';
    import CustomerSelectList from '@/components/customer/CustomerSelectList';
    import { getByKey } from '@/services/setting.service';
    import { fetchStatuses } from '@/services/control-position-status.service';

    export default {
        name: 'App',
        components: {
            MenuLogotype,
            NavbarLogotype,
            CustomerSelectList

        },
        data() {
            return {
                isMenuCollapsed: false,
                isOnMobile: false,
                isOnTablet: false
            };
        },
        computed: {
            ...mapState({
                user: (state) => state.user,
                customerMenu: (state) => state.archive.menu,
                customerAdminMenu: (state) => state.archive.customerAdminMenu,
                adminMenu: (state) => state.archive.adminMenu,
                superAdminMenu: (state) => state.archive.superAdminMenu
            }),
            menu() {
                let menuItems = [
                    {
                        component: MenuLogotype,
                        props: {
                            isMenuCollapsed: this.isMenuCollapsed,
                            user: this.user
                        },
                        //hidden: false
                        hiddenOnCollapse: false
                    },
                    {
                        component: CustomerSelectList,
                        hiddenOnCollapse: true
                    },
                    ...this.customerMenu
                ];

                // Add admin menu if admin.
                if (!this.user.isLoading) {
                    if (this.user.user.roles.includes('SuperAdmin'))
                        menuItems = [...menuItems, ...this.superAdminMenu];
                    else if (this.user.user.roles.includes('Administrator'))
                        menuItems = [...menuItems, ...this.adminMenu];
                    else if (this.user.user.roles.includes('CustomerAdmin'))
                        menuItems = [...menuItems, ...this.customerAdminMenu];
                }

                return menuItems;
            }
        },
        async mounted() {
            // Get from vuex.
            await this.getUser();

            if (!getByKey('isOffline')) {
                await this.getMenu();
                await fetchStatuses();
            }

            this.onResize();
        },
        created() {
            window.addEventListener('resize', this.onResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            ...mapActions('user', ['getUser']),
            ...mapActions('user', ['setCustomer']),
            ...mapActions('user', ['setBuilding']),
            ...mapActions('archive', ['getMenu']),
            onToggleCollapse(collapsed) {
                this.isMenuCollapsed = collapsed;
            },
            onItemClick(event, item, node) {
                // Close meny if on mobile and has no children.
                if (!item.child && this.isOnMobile)
                    this.isMenuCollapsed = true;
            },
            collapseMenuOnOutsideClick() {
                this.isMenuCollapsed = true;
            },
            onResize() {
                if (window.innerWidth <= 768) {
                    this.isOnTablet = false;
                    this.isOnMobile = true;
                    this.isMenuCollapsed = true;
                }
                else if (window.innerWidth <= 1024) {
                    this.isOnTablet = true;
                    this.isOnMobile = false;
                    this.isMenuCollapsed = true;
                }
                else {
                    this.isOnTablet = false;
                    this.isOnMobile = false;
                    this.isMenuCollapsed = false;
                }
            },
            async logout(event) {
                event.preventDefault();
                await this.setCustomer(null);
                await this.setBuilding(null);
                window.location.href = '/logout';
            }
        }
    };
</script>

<style lang="scss">
@import 'assets/scss/all.scss';
</style>

<style scoped>
    .sidebar-menu-mobile--toggle-button-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 100;
    }

        .sidebar-menu-mobile--toggle-button-wrapper > button {
            color: #7f8d9d;
            background-color: #272e37;
            border: 0px;
            border-radius: 0;
        }

    #app {
        padding-top: 0px;
        transition: 0.3s ease;
    }

        #app.isOnline:not(.collapsed) {
            padding-top: 15px;
            padding-left: 290px;
        }

        #app.isOnline:is(.onmobile) {
            padding-top: 0px;
            padding-left: 0px;
        }

        #app:not(.isOnline):is(.collapsed) {
            padding-left: 0px;
        }

        #app.collapsed {
            padding-top: 7px;
            padding-left: 50px;
        }

        #app.onmobile:not(.collapsed) .v-sidebar-menu {
            display: flex;
        }

        #app.onmobile {
            padding-top: 7px;
            padding-left: 0px;
        }

            #app.onmobile .v-sidebar-menu {
                display: none;
          
            }

    

    .sidebar-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.5;
        z-index: 900;
    }

    .topMenu {
        margin: -7px 0 7px 0;
    }
</style>

<style>
    .menu-divider {
        height: 1px;
        background-color: #4a5461;
        margin: 10px 10px;
    }
</style>